
import ChooseYourRoute from '~/components/home/ChooseYourRoute'
import AboutFionaWithTestimonials from '~/components/shared/AboutFionaWithTestimonials'
import PopularBlogPosts from '~/components/shared/PopularBlogPosts'
import GetYourGuideBottom from '~/components/shared/GetYourGuideBottom'
import HowCanIHelpYou from '~/components/home/HowCanIHelpYou'
import GetYourGuideTop from '~/components/home/GetYourGuideTop'
import Hero from '~/components/home/Hero'

export default {
  name: 'IndexPage',
  components: {
    Hero,
    GetYourGuideTop,
    HowCanIHelpYou,
    GetYourGuideBottom,
    PopularBlogPosts,
    AboutFionaWithTestimonials,
    ChooseYourRoute,
  },
  data() {
    return {
      title: `The Ultimate Plant-Based Resource | ${this.$config.siteTitle}`,
      description:
        'Learn to thrive on a plant-based diet with expertly crafted plant-based nutrition and cooking resources.',
    }
  },
  head() {
    return {
      title: this.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.description,
        },
        { hid: 'og:title', property: 'og:title', content: this.title },
        { hid: 'og:url', property: 'og:url', content: this.pageUrl },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.description,
        },

        // twitter card
        { hid: 'twitter:title', name: 'twitter:title', content: this.title },
        { hid: 'twitter:url', name: 'twitter:url', content: this.pageUrl },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.description,
        },
        { hid: 'og:image', name: 'og:image', content: '/images/home/og.png' },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: '/images/home/og.png',
        },
        {
          hid: 'og:image:width',
          name: 'og:image:width',
          content: '1200',
        },
        {
          hid: 'og:image:height',
          name: 'og:image:height',
          content: '630',
        },
      ],
    }
  },
  computed: {
    pageUrl() {
      return `${this.$config.baseURL}${this.$route.fullPath}`
    },
  },
}

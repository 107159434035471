
import ArticlePreviewCard from '~/components/shared/ArticlePreviewCard'
export default {
  name: 'PopularBlogPostsComponent',
  components: { ArticlePreviewCard },
  data: () => ({
    featuredPosts: [],
  }),
  async fetch() {
    await this.$axios
      .$get(
        'https://api.plantssogood.com/wp-json/wp/v2/posts?per_page=3&sticky=true&status=publish&acf_format=standard'
      )
      .then((res) => {
        this.featuredPosts = res
      })
  },
}

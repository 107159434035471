
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    id() {
      return this.post.id
    },
    title() {
      return this.post.title.rendered
    },
    slug() {
      return this.post.slug
    },
    image() {
      const acfData = this.post.acf
      let finalImage = null
      if (
        acfData &&
        Object.prototype.hasOwnProperty.call(acfData, 'pinterest_pin_image')
      ) {
        finalImage =
          acfData.pinterest_pin_image !== false
            ? acfData.pinterest_pin_image
            : this.post.featured_image_src
      } else {
        finalImage = this.post.featured_image_src
      }
      if (finalImage && typeof finalImage === 'string')
        return finalImage.replace(
          'https://api.plantssogood.com',
          'https://ik.imagekit.io/rajumandapati/psg'
        )
      return null
    },
  },
}
